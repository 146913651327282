import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout title="Web">
    <h1>How to build a website that doesn't suck</h1>
    <h2>The bar is surprisingly higher than you think</h2>
  </Layout>
)

